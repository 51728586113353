'use strict';

const helpers = {
	getWindowWidth() {
		return document.body.getBoundingClientRect().width;
	},

	isMobile: function () {
		return !(this.getWindowWidth() >= 768);
	},

	isTouchDevice: function () {
		return window.ontouchstart !== undefined;
	},

	isTablet: function () {
		Boolean(document.body.getBoundingClientRect().width < 1024 && document.body.getBoundingClientRect().width > 768);
	},

	/// @TOSEEScreen.orientation
	getOrientation: function () {
		return window.innerHeight > window.innerWidth ? 'portrait' : 'paysage';
	},

	getCookie: function (name) {
		let value = `; ${document.cookie}`;
		let parts = value.split(`; ${name}=`);
		if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
	},

	setCookie: function (name, value, days) {
		var d = new Date;
		d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
		document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
	},

	deleteCookie: function (name) {
		this.setCookie(name, '', -1);
	},

	getPage(page) {
		if (page !== undefined) {
			return document.getElementById('id1').value === page; /// on est bien sur la page correspondante à id
		} else {
			return document.getElementById('id1').value;
		}
	},

	elementExist(identifiant) {
		return document.querySelector(identifiant) !== null;
	},

	isEmptyObject(object) {
		for (const property in object) {
			return false;
		}
		return true;
	},

	hide(el) {
		el.style.display = 'none';
	},

	show(el, value = 'block') {
		el.style.display = value;
	},

	_dispatchEventSubmitForm(obj, selector) {
		document.querySelector(selector).dispatchEvent(
			new CustomEvent('submitForm', {
				bubbles: true,
				detail: {
					libelle: () => {
						return obj
					}
				}
			})
		);
	},

	simulateEvent(id, evt) {

		const trigger = (el, etype, custom) => {
			const evt = custom ?? new Event(etype, {
				bubbles: true
			});
			el.dispatchEvent(evt);
		};
		trigger(document.querySelector(`[data-simulated="${id}"]`), evt);
	},

	readMore(container = '', selector, classToggle, hasHtmlContent, states) {
		let self = this,
			libelle = states || {
				true: 'Afficher plus',
				false: 'Afficher moins'
			},
			delay = 200;

		// On masque le contenu avec un décalage
		setTimeout(function () {
			document.querySelectorAll(`${container} [data-hide="true"]`).forEach(el => {

				if (el.id === 'partageValeur') {
					libelle = {
						true: 'En savoir plus',
						false: 'Moins de détails'
					}
				}
				// Cas Présentation

				if (el.querySelector('[data-readmore]') !== null) {
					let nbLignesAffichees = +getComputedStyle(el).getPropertyValue('--nblines'),
						hauteurLigne = isNaN(parseInt(getComputedStyle(el).lineHeight, 10)) ? parseInt(getComputedStyle(el).getPropertyValue('--app-lineHeight')) : parseInt(getComputedStyle(el.querySelector('[data-readmore]')).lineHeight, 10),
						hauteurBloc = self.offset(el.querySelector('[data-readmore]')).height,
						hauteurButton = el.querySelector('[data-toggle]') !== null ? self.offset(el.querySelector('[data-toggle]')).height + parseInt(getComputedStyle(el.querySelector('[data-readmore]')).marginBottom, 10) + parseInt(getComputedStyle(el.querySelector('[data-toggle]')).marginTop, 10) : 0;
					/// Si la hauteur du bloc non masqué > (Nombre de lignes affichées + 1) * hauteur d'une ligne
					/// on masque les lignes 2 + n
					/// sinon on masque le bouton 'Lire la suite'
					if (hauteurBloc > (nbLignesAffichees * hauteurLigne + hauteurButton)) {
						el.classList.add('is-overflow');
					} else {
						el.querySelector('[data-toggle]') != null && el.querySelector('[data-toggle]').remove();
					}
				} else {
					el.classList.add('is-overflow');
				}
			});
		}, delay);

		document.querySelectorAll(`${container} ${selector}`).forEach(el => {
			el.addEventListener('click', function (e) {
				e.target.parentElement.classList.toggle(classToggle);
				e.target.dataset.toggle = !JSON.parse(e.target.dataset.toggle);
				/// On garde le <svg>
				if (hasHtmlContent) {
					if (!self.isObjectEmpty(libelle)) {
						e.target.childNodes[1].nodeValue = libelle[e.target.dataset.toggle];
					}
				} else {
					e.target.textContent = libelle[e.target.dataset.toggle];
				}
			})
		});
	},

	///
	/// Switch lien mobile span si desktop
	///
	switchTelDesktop: function (a) {
		let id = a;

		if (a === '') {
			id = `tel-${Math.random().toString(36).slice(2)}`;
			document.querySelectorAll('main [href^="tel:"]').forEach(el => {
				el.id = id;
			})
		}

		let selector = document.getElementById(id),
			numero = selector.innerText,
			wrapper = document.createElement('span');
		wrapper.id = id;

		selector.parentNode.insertBefore(wrapper, selector);
		selector.remove();

		wrapper.innerText = numero
	},

	isObjectEmpty(obj) {
		return Object.keys(obj).length === 0 && obj.constructor === Object
	},

	offset(elem) {
		var rect = elem.getBoundingClientRect(),
			bodyElem = document.body;
		return {
			bottom: rect.bottom,
			height: rect.height,
			top: rect.top + bodyElem.scrollTop,
			left: rect.left + bodyElem.scrollLeft
		}
	},

	uncheckOthersOnCheck(blockParentSelector) {
		let checkboxes = document.querySelectorAll(`${blockParentSelector} input`);
		document.querySelector(`${blockParentSelector}`).addEventListener('change', function (e) {
			checkboxes.forEach((el) => {
				el.checked = el.id !== e.target.id ? false : e.target.checked;
			})
		});
	},

	setActiveItemInactiveSiblings(arrayOfItems, itemToActive, callback, activeClass = 'is-active') {
		[...arrayOfItems].forEach(sib => sib.classList.remove(activeClass));
		itemToActive.classList.add(activeClass);
		typeof callback === 'function' && callback();
	}
}

module.exports = helpers;