var modalPopup = {

	overrideStyles: true,
	emptyOnClose: true,

	items: {
		popupModal: '[data-popup-modal="modal"]',
		popupTitle: '.popup-title',
		popupContent: '.popup-content',
		closeButton: '.popup-modal__close',
		overlayBody: '.body-blackout',
		classPopupVisible: 'is--visible',
		classOverlayBodyVisible: 'is-blacked-out',
		classBody: 'has-overlay',
		idButtonValid: 'popupValidButton',
		idButtonCancel: 'popupCancelButton'
	},

	overrideStylesFunction: function () {
		var items = this.items;

		document.querySelector(items.popupModal).style.width = '800px';
		document.querySelector(items.closeButton).style.width = '30px';
		document.querySelector(items.closeButton).style.height = '30px';
	},

	open: function (text, title, libelleCancelButton, libelleValidButton) {
		var items = this.items;
		document.querySelector(items.popupModal).classList.add(items.classPopupVisible);
		document.querySelector(items.overlayBody).classList.add(items.classOverlayBodyVisible);
		document.body.classList.add(items.classBody);
		document.querySelector(items.popupModal).querySelector(items.popupTitle).innerHTML = (title === undefined) ? '' : title;
		document.querySelector(items.popupModal).querySelector(items.popupContent).innerHTML = text;
		if (document.getElementById(this.items.idButtonCancel) != null) {
			document.getElementById(this.items.idButtonCancel).textContent = libelleCancelButton;
		}
		if (document.getElementById(this.items.idButtonValid) != null) {
			document.getElementById(this.items.idButtonValid).textContent = libelleValidButton;
		}

		if (document.getElementById(this.items.idButtonValid) !== null) {
			if (libelleValidButton === "")
				document.getElementById(this.items.idButtonValid).style.visibility = "hidden";
			else {
				document.getElementById(this.items.idButtonValid).style.visibility = "visible";
			}
		}
	},

	reopen: function () {
		var items = this.items;
		document.querySelector(items.popupModal).classList.add(items.classPopupVisible);
		document.querySelector(items.overlayBody).classList.add(items.classOverlayBodyVisible);
		document.body.classList.add(items.classBody);
	},

	close: function () {
		var items = this.items;
		document.querySelector(items.popupModal).classList.remove(items.classPopupVisible);
		document.querySelector(items.overlayBody).classList.remove(items.classOverlayBodyVisible);
		document.body.classList.remove(items.classBody);
		if (modalPopup.emptyOnClose) {
			document.querySelector(items.popupModal).querySelector(items.popupTitle).innerHTML = '';
			document.querySelector(items.popupModal).querySelector(items.popupContent).innerHTML = '';
		}

	},

	eventClose: function () {
		var items = this.items;
		document.querySelector(items.closeButton).addEventListener('click', function () {
			modalPopup.close();
		});
		document.querySelector(items.overlayBody).addEventListener('click', function () {
			modalPopup.close();
		});
	},

	eventChoose: function () {
		modalPopup.reinitListener();
		modalPopup.eventClose();
		if (document.getElementById(this.items.idButtonValid) != null) {
			document.getElementById(this.items.idButtonValid).addEventListener('click', function () {
				modalPopup.callback();
			});
		}
		if (document.getElementById(this.items.idButtonCancel) != null) {
			document.getElementById(this.items.idButtonCancel).addEventListener('click', function () {
				modalPopup.close();
			});
		}
	},

	callbackFunctionLaunch() {
		if ("callback" in modalPopup) {
			modalPopup.eventChoose();
		}
	},

	reinitListener() {
		/// Permet de remove tous les listeners
		document.querySelector(this.items.popupModal).replaceWith(document.querySelector(this.items.popupModal).cloneNode(true));
	},

	init: function (text, title, libelleCancelButton, libelleValidButton) {
		this.open(text, title, libelleCancelButton, libelleValidButton);
		this.eventClose();
		this.callbackFunctionLaunch();
		this.overrideStyles && this.overrideStylesFunction();
	}
}

module.exports = modalPopup;