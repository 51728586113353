/**
 * Le block <carousel-img> appelé pour afficher un slider
 * DOC : https://developer.mozilla.org/fr/docs/Web/API/Web_components/Using_custom_elements
 */
class ScrollSnap extends HTMLElement {
	constructor() {
		super();
		this.shadow = this.attachShadow({
			mode: "open"
		});

		/// Les attributs permettant de personnaliser le carousel
		this.alignment = this.dataset.alignment === undefined ? "center" : this.dataset.alignment;
		this.width = this.dataset.width === undefined ? "100%" : this.dataset.width;
		this.gap = this.dataset.gap === undefined ? 0 : this.dataset.gap;
		this.count = this.dataset.count === undefined ? 4 : this.dataset.count;
		this.hasBullets = (this.dataset.hasBullets !== undefined && this.count > 1);
		this.hasBulletsHover = (this.dataset.hasBulletsHover == undefined);
		this.infinity = this.dataset.infinity === undefined ? false : this.dataset.infinity;
		this.coeffArrow = this.dataset.sizeArrow === undefined ? 1 : this.dataset.sizeArrow;
		this.opacityArrow = this.dataset.opacityArrow;
		this.cached = this.dataset.cached !== undefined;

		/// Les méthodes appelées
		this.scrollToNextPage = this.scrollToNextPage.bind(this);
		this.scrollToPreviousPage = this.scrollToPreviousPage.bind(this);
		this.calculateGalleryItemSize = this.calculateGalleryItemSize.bind(this);

		this.isTouch = window.ontouchstart !== undefined;
	}

	/**
	 * @memberof ScrollSnap
	 * Appelé lorsque l'élément personnalisé est connecté pour la première fois au DOM du document
	 */
	connectedCallback() {
		this.render();

		this.gallery = this.shadowRoot.querySelector(".gallery");
		this.galleryScroller = this.gallery.querySelector(".gallery-scroller");

		this.currentIndex = 0
		this.hasBullets && !this.isTouch && this.setActivebullet(0);
		this.calculateGalleryItemSize();
		this.setIfLastShow();

		/// Evenements
		this.gallery.addEventListener("click", e => {
			let self = this;

			const {
				target
			} = e;

			target.dispatchEvent(
				new CustomEvent('clickGallery', {
					bubbles: true,
					detail: {
						libelle: () => {
							return target.dataset.libelle
						}
					}
				})
			);

			/// Permettre lorsque l'utilisateur click sur l'image, cela suive le lien [data-expand-link]
			/// @use : block circuit des LR
			if (target.localName === 'img' && target.closest('[data-expand-target="true"]') != null) {
				window.location.href = target.closest('[data-expand-target="true"]').querySelector('[data-expand-link]').href ?? target.closest('[data-expand-target="true"]').querySelector('[data-expand-link]').dataset.url;
			}
			///
			/// La navigation par bullets
			else if (target.localName === 'circle' && this.hasBullets) {
				let indexBullet = [...target.parentElement.children].indexOf(target);
				let coeff = indexBullet - this.currentIndex;
				this.galleryScroller.scrollBy(coeff * this.galleryItemSize, 0);
				this.currentIndex = indexBullet;
				this.setActivebullet(indexBullet);
			}

			if (self.cached) {
				self.querySelectorAll('img').forEach((el, index) => {
					if (index > 0 && el.src != el.dataset.src && el.src.includes('VIDE')) {
						el.src = el.dataset.src;
					}
				})
			}
		});

		if (this.count > 1) {
			this.gallery.addEventListener('touchstart', (e) => {
				this.scrollHandler(e, this.gallery)
			});

			this.galleryScroller.scrollLeft = 0;

			this.gallery
				.querySelector("button.next")
				.addEventListener("click", this.scrollToNextPage);

			this.gallery
				.querySelector("button.previous")
				.addEventListener("click", this.scrollToPreviousPage);
		}


		/** Recalculate the gallery item size when the window is resized. */
		window.addEventListener("resize", this.calculateGalleryItemSize);
	}

	/**
	 * Getter : on récupère l'index du premier item affiché
	 * @memberof ScrollSnap
	 */
	get currentIndex() {
		return this.shadowRoot.querySelector(".gallery").dataset.index === undefined ? 0 : this.shadowRoot.querySelector(".gallery").dataset.index
	}

	/**
	 * Setter : on update l'index du premier item affiché
	 * @memberof ScrollSnap
	 */
	set currentIndex(newIndex) {
		this.shadowRoot.querySelector(".gallery").dataset.index = newIndex
	}

	/**
	 * Called when the element is removed from the DOM.
	 * @memberof ScrollSnap
	 */
	disconnectedCallback() {
		window.removeEventListener("resize", this.calculateGalleryItemSize);
	}

	/**
	 * On calcule la largeur d'un item
	 * @memberof ScrollSnap
	 */
	calculateGalleryItemSize() {
		const slotElement = this.galleryScroller.querySelector("slot");
		const nodes = slotElement.assignedNodes({
			flatten: true
		});
		const firstSlottedElement = nodes.find(
			(node) => node.nodeType === Node.ELEMENT_NODE
		);
		this.galleryItemSize = firstSlottedElement.clientWidth === 0 ? this.parentNode.clientWidth : firstSlottedElement.clientWidth;
	}
	/**
	 * Renvoie un boolean indiquant si le dernier item du carousel est visible ou non
	 * @memberof ScrollSnap
	 */
	setIfLastShow(index) {
		// On calcule le nombre d'items affichés simulatnément
		this.nbItemsShow = Math.floor(this.parentNode.clientWidth / (Number(this.gap), this.galleryItemSize));

		// Quel est l'index du dernier élément visible ?
		this.lastVisible = (this.lastVisible || Number(this.nbItemsShow)) + (index || 0);

		// On set un boolean pour gérer les flèches
		this.gallery.dataset.lastShow = (Number(this.count) - this.lastVisible) === 0;
	}

	/**
	 * On détect si l'on swipe
	 * @memberof ScrollSnap
	 */
	scrollHandler(e) {
		[...e.target.parentElement.children].forEach((el, index) => {
			if (index > 0 && el.src != el.dataset.src && el.src.includes('VIDE')) {
				el.src = el.dataset.src;
			}
		});
	}

	/**
	 * Go to item précédent
	 * @memberof ScrollSnap
	 */
	scrollToPreviousPage() {
		this.galleryScroller.scrollBy(-this.galleryItemSize, 0);
		let currentIndex = this.gallery.dataset.index === undefined ? 0 : this.gallery.dataset.index;
		this.gallery.dataset.index = Math.max(Number(currentIndex) - 1, 0);
		(this.hasBullets && !this.isTouch) && this.setActivebullet(this.gallery.dataset.index)
		this.setIfLastShow(-1);
	}

	/**
	 * Go to item suivant
	 * @memberof ScrollSnap
	 */
	scrollToNextPage() {
		this.galleryScroller.scrollBy(this.galleryItemSize, 0);
		let currentIndex = this.gallery.dataset.index === undefined ? 0 : this.gallery.dataset.index;
		this.gallery.dataset.index = Math.min(Number(currentIndex) + 1, Number(this.count - 1));
		(this.hasBullets && !this.isTouch) && this.setActivebullet(this.gallery.dataset.index);

		if (JSON.parse(this.gallery.dataset.lastShow) && this.infinity) {
			this.galleryScroller.scrollLeft = 0;
			this.gallery.dataset.index = 0;
			this.setIfLastShow(-1 * this.count);
		} else {
			this.setIfLastShow(1);
		}
	}

	/**
	 * Active bullet
	 * @memberof ScrollSnap
	 */
	setActivebullet(index) {
		this.bullets = this.gallery.querySelectorAll(`circle`);
		this.bullets.forEach(el => el.classList.remove('is-active'))
		this.bullets[index].classList.add('is-active')
	}

	/**
	 * Génération des bullets
	 * @memberof ScrollSnap
	 * @returns [HTML]
	 */
	generateBullets() {
		let circles = '';
		for (let i = 0; i < this.count; i++) {
			circles += `<circle cx="${5 + i * 12}" cy="5" r="3" />`;
		}

		let bullets = `<svg width="47" height="10" viewBox="0 0 47 10" xmlns="http://www.w3.org/2000/svg">${circles}</svg>`;
		bullets = this.hasBullets ? bullets : '';
		bullets = !this.isTouch ? bullets : '';
		bullets = this.count > 1 ? bullets : '';
		return bullets;
	}
	/**
	 * Handles the rendering of the component markup and CSS.
	 */
	render() {

		this.shadowRoot.innerHTML = `
			<style>
				* {
					scroll-behavior: smooth;
				}
				::slotted(*) {
					margin: 0;
					min-width: ${this.width};
					min-height: 100%;
					position: relative;
					scroll-snap-align: start;
					transition: opacity .5s ease-in
				}
				.gallery {
					position: relative;
					z-index:12;
					cursor: pointer;
					max-width:100%;
					overflow: hidden
				}

				.gallery-scroller {
					scrollbar-width: none;
					-webkit-overflow-scrolling: touch;
					align-items: ${this.alignment};
					display: flex;
					gap:${this.gap}px;
					overflow-x: scroll;
					overflow-y: hidden;
					scroll-snap-type: x mandatory;
				}
				.gallery-scroller::-webkit-scrollbar {
					display: none;
				}

				.touch .gallery:hover button {opacity: 0}
				.gallery:hover button {opacity: ${Number(this.opacityArrow ?? 1)};}

				button {
					background-color: var(--bgBtn,transparent);
					background-position: 50% 50%;
					background-repeat: no-repeat;
					border-radius: var(--bgRadius, 0);
					box-shadow: none;
					border: var(--borderBtn, none);
					height: var(--sizeBtn,34px);
					position: absolute;
					top: 50%;
					opacity: ${this.isTouch ? 0 : Number(this.opacityArrow ?? !this.hasBulletsHover)};
					width: var(--sizeBtn,34px);
					z-index: 99;
					filter: var(--filterBtn, invert(100%));
				}

				button:hover {
					cursor: pointer;
				}

				button.next {
					background-image: url('data:image/svg+xml;charset=utf-8,<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><path d="M16.282,16.282,3.542,29.022A2.075,2.075,0,1,1,.606,26.088L11.879,14.815.608,3.543A2.075,2.075,0,0,1,3.542.609l12.74,12.74a2.075,2.075,0,0,1,0,2.932Zm0,0"></path></svg>');
					right: 10px;
					transform: translateY(-50%) scale(calc(1 * ${this.coeffArrow}));

				}

				button.previous {
					background-image: url('data:image/svg+xml;charset=utf-8,<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><path d="M16.282,16.282,3.542,29.022A2.075,2.075,0,1,1,.606,26.088L11.879,14.815.608,3.543A2.075,2.075,0,0,1,3.542.609l12.74,12.74a2.075,2.075,0,0,1,0,2.932Zm0,0"></path></svg>');
					left: 10px;
					transform: translateY(-50%) scale(calc(-1 * ${this.coeffArrow}));
				}


				.gallery:not([data-index]) button.previous,
				[data-index="0"] button.previous,
				${!this.infinity ? `[data-last-show="true"] button.next`: `:not([data-infinity="true"]) [data-last-show="true"] button.next`} {
					display:none
				}
				svg	 {
					position: absolute;
					z-index:1000;
					fill: #fff;
					left:50%;
					bottom: 20px;
					transform: translateX(-50%)
				}

				circle:not(.is-active) {opacity: .3}


			</style>

			<div class="gallery">
					${this.count > 1 ? `<button class="previous" aria-label="Previous"></button>
					<button class="next" aria-label="Next"></button>` : ""}
					${this.generateBullets() }
					<div class="gallery-scroller">
						<slot></slot>
					</div>
			</div>
			<slot name="disclaimer"></slot>
				`;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	/// On ajoute le nouvel élément
	customElements.define("carousel-img", ScrollSnap);
})