'use strict';

const helpers = require("./helpers");

module.exports = class dialogElement {
	constructor() {
		this.btn = document.querySelectorAll('[data-dialog-btn]');
	}
	initialize() {
		this.btn.forEach(elem => {
			let dialogElement = document.getElementById(elem.dataset.dialogBtn),
					closeElement = dialogElement.querySelector('[value="close"]');
			elem.addEventListener('click', function onOpen(e) {
				if(e.target.value !== 'close') {
					if (typeof dialogElement.showModal === "function") {
						if(!helpers.isTouchDevice()) {
							let wrapper = dialogElement.parentElement;
							dialogElement.style.left = helpers.offset(wrapper).left + "px";
							dialogElement.style.top = helpers.offset(wrapper).top + "px";
							document.body.style.overflow = 'hidden';
						}
						dialogElement.showModal();
					} else {
						console.error("L'API <dialog> n'est pas prise en charge par ce navigateur.");
					}
				}
			});
			closeElement.addEventListener('click', function onOpen(e) {
				if (typeof dialogElement.close === "function") {
					!helpers.isTouchDevice() && document.body.removeAttribute('style');
					dialogElement.close();
				} else {
					console.error("L'API <dialog> n'est pas prise en charge par ce navigateur.");
				}
			});

			this.closeDialog(dialogElement)
		});


	}
	closeDialog(el) {
		document.body.addEventListener('click', (e) => {
			if(e.target.localName === 'dialog')  {
				if (typeof el.close === "function") {
					el.close();
					!helpers.isTouchDevice() && document.body.removeAttribute('style');
				} else {
					console.error("L'API <dialog> n'est pas prise en charge par ce navigateur.");
				}
			}
		})
	}
}