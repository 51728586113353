/*
 * Configuration RGPD
 */

const tld = window.location.origin.split('.').pop();

let cookiesSettings = {

	typeCookies: [
		"facebook_pixel", "Bing", "Google_Ads", "ABTasty", "terdav", "google_analytics", "emarsys", "clarity"
	],

	defaultCookieInfos: {
		path: '/',
		domain: window.location.hostname === 'localhost' ? window.location.hostname : '.terdav.' + tld
	},

	objCookies: require('common/helpers').getCookie(),

	/// Liste des cookies
	facebook_pixel: {
		nameCookies: ['_fbp'],
		hasStorage: false,
		hasFunction: true
	},
	Bing: {
		nameCookies: ['_uetvid', '_uetsid', 'MUID'],
		domain: 'bing.com',
		hasStorage: true,
		nameLocalStorageItems: ['_uetvid', '_uetvid_exp', '_uetsid', '_uetsid_exp']
	},
	Google_Ads: {
		nameCookies: ['APISID', 'SAPISID', 'SSID', 'IDE'],
		hasStorage: false
	},
	ABTasty: {
		nameCookies: ['ABTasty', 'ABTastySession', 'ABTastyDomainTest'],
		hasStorage: true,
		nameLocalStorageItems: ['ABTastyData', 'ABTastyWidgets'],
		nameSessionStorageItems: ['ABTastyUA', 'ABTastyGeoloc']
	},
	terdav: {
		nameCookies: ['terdav'],
		hasStorage: false
	},
	google_analytics: {
		nameCookies: ['_ga', '_gid', '_gat_gaLegacy', '_gali', '_dc_gtm_UA-39580963-3', '_gat_UA-39580963-3', '_pctx', '_pcid'],
		hasStorage: false
	},
	clarity: {
		nameCookies: ['_clsk', '_clck', '_cltk'],
		hasStorage: true
	},
	emarsys: {
		nameCookies: ['scarab.visitor', 'scarab.profile'],
		hasStorage: false
	},

	removeCookie: function (trackeur) {
		/// On récupère la liste des cookies de l'objet cookiesSettings[trackeur]
		/// on les supprime
		for (var i = 0; i < cookiesSettings[trackeur].nameCookies.length; i++) {
			require('common/helpers').deleteCookie(cookiesSettings[trackeur].nameCookies[i])
		}
	},

	removeStorage: function (trackeur) {
		/// On supprime les datas dans localStorage et sessionStorage de trackeur
		if (cookiesSettings[trackeur].nameLocalStorageItems != undefined) {
			for (var i = 0; i < cookiesSettings[trackeur].nameLocalStorageItems.length; i++) {
				localStorage.removeItem(cookiesSettings[trackeur].nameLocalStorageItems[i]);
			}
		}
		if (cookiesSettings[trackeur].nameSessionStorageItems != undefined) {
			for (var i = 0; i < cookiesSettings[trackeur].nameSessionStorageItems.length; i++) {
				localStorage.removeItem(cookiesSettings[trackeur].nameSessionStorageItems[i]);
			}
		}
	},

	removeData: function (trackeur) {
		cookiesSettings.removeCookie(trackeur);
		if (cookiesSettings[trackeur].hasStorage) {
			cookiesSettings.removeStorage(trackeur);
		}
	},

	removeAllCookies: function () {
		for (var i = 0; i < cookiesSettings.typeCookies.length; i++) {
			cookiesSettings.removeCookie(cookiesSettings.typeCookies[i]);
		}
	}

	// info: function () {
	// 	for (var i = 0; i < cookiesSettings.typeCookies.length; i++) {
	// 		let nameOfService = cookiesSettings.typeCookies[i];
	// 		let numberOfCookies = cookiesSettings[nameOfService].nameCookies.length;
	// 		let listOfCookies = [];

	// 		console.log(numberOfCookies)

	// 		for (var i = 0; i < numberOfCookies; i++) {
	// 			listOfCookies.push(cookiesSettings[nameOfService].nameCookies[i])
	// 		}
	// 		console.info(`${nameOfService} dépose ${numberOfCookies} cookies : ${listOfCookies}`);

	// 	}
	// }
}


/*
 * Implémentation des trackeurs
 */
const injectionTrakers = {

	launchFB() {
		! function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ?
					n.callMethod.apply(n, arguments) : n.queue.push(arguments)
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s)
		}(window,
			document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

		fbq('init', '1903867123167252');
		fbq('track', "PageView");
	},

	launchGoogleAd() {
		var el = document.createElement('script');
		el.setAttribute('async', true);
		el.setAttribute('src', '//www.googleadservices.com/pagead/conversion.js');
		document.body.appendChild(el);
	}
}


/*
 * Gestion style si l'on bloque la navigation jusqu'à l'acceptation ou non des cookies
 */
const setPopinObligatoire = function (a) {
	if (a) {
		var css = document.createElement('style');
		css.type = 'text/css';

		var styles = '.axeptio-widget--open .axeptio_mount { background: rgba(0, 0, 0, .5); height: 100%; width: 100%; position: absolute; top: 0; }';
		styles += '.axeptio-widget--open { overflow-y: hidden;}';

		if (css.styleSheet) css.styleSheet.cssText = styles;
		else css.appendChild(document.createTextNode(styles));

		document.getElementsByTagName("head")[0].appendChild(css);
	}
}
/*
 * Gestion style : popin au centre de la page
 */
const setPopinCentre = function (a) {
	if (a) {
		var css = document.createElement('style');
		css.type = 'text/css';

		var styles = '@media(min-width:450px){.ax-widget-container {left: 50%!important;bottom:50vh!important; transform: translate( -50%,50%)!important; } .ax-widget-container .Illustration__Img{transform:translateX(20px)}}';

		if (css.styleSheet) css.styleSheet.cssText = styles;
		else css.appendChild(document.createTextNode(styles));

		document.getElementsByTagName("head")[0].appendChild(css);
	}
}

/*
 * Si le consentment n'a pas été donné, on supprime les cookies
 */
if (require('common/helpers').getCookie('axeptio_cookies') === undefined) {
	for (const property in cookiesSettings.objCookies) {
		require('common/helpers').setCookie(property, '', {
			expires: 7
		}) /// On le redéclare pour pouvoir l'effacer (cas date expiré)
		require('common/helpers').deleteCookie(property);
	}
}

/*
 * Initialisation popin Axeptio
 */
void 0 === window._axcb && (window._axcb = []);
window._axcb.push(function (axeptio) {

	/// À l'ouverture de la popin, on détecte si l'option consentement obligatoire est true ; si oui, on ajoute le style adéquat
	axeptio.on('ready', function (choices) {
		setPopinObligatoire(axeptioSettings.terdavConsentObligatoire);
		setPopinCentre(axeptioSettings.terdavPopinMiddle);
	});

	axeptio.on('cookies:complete', function (choices) {

		///
		/// Les logiciels tiers gérés depuis le back
		///
		/// FACEBOOK PIXEL
		if (choices.facebook_pixel) {
			injectionTrakers.launchFB();
		} else {
			cookiesSettings.removeData('facebook_pixel');
		}

		/// GOOGLE ADS
		if (choices.Google_Ads) {
			injectionTrakers.launchGoogleAd();
		} else {
			cookiesSettings.removeData('Google_Ads');
		}

		/// TERDAV
		if (!choices.terdav || choices.terdav === undefined) {
			cookiesSettings.removeData('terdav');
		}

		///
		/// Les cookies installés par un logiciel tiers depuis GTM
		///

		/// GOOGLE ANALYTICS
		if (!choices.google_analytics || choices.google_analytics === undefined) {
			cookiesSettings.removeData('google_analytics');
		}

		/// AB TASTY
		if (!choices.ABTasty || choices.ABTasty === undefined) {
			cookiesSettings.removeData('ABTasty');
		}

		/// BING
		if (!choices.Bing || choices.Bing === undefined) {
			cookiesSettings.removeData('Bing');
		}

		/// clarity
		if (!choices.clarity || choices.clarity === undefined) {
			cookiesSettings.removeData('clarity');
		}
		/// Emarsys
		if (!choices.emarsys || choices.emarsys === undefined) {
			cookiesSettings.removeData('emarsys');
		}
	});
});