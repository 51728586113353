'use strict';

var commons = {
	classConfig: {
		loading: 'loading'
	},
	NAVIGATOR: {
		isiPad: () => {return /ipad/i.test(navigator.userAgent.toLowerCase())},
		isSafari: () => {return /constructor/i.test(window.HTMLElement)},
		isMobileSafari: () => {return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)},
		isDeviceiPad: (settings) => {return /safari/i.test(navigator.userAgent.toLowerCase()) && settings.helpers.getWindowWidth() === 768}
	},

	init(settings) {
		/// Appel des méthodes communes à l'ensemble du site
		this.convertButtonToLink();
		this.loader.loaded();
		this.configCss(settings, this.NAVIGATOR);
		this.flecheScrollTop();
		this.resizeScreen(settings, this);

		/// Appel des customs elements
		customElements.define('box-rgpd', rgpd);
		customElements.define('box-niveaux', niveaux);
	},

	convertButtonToLink(attribute = 'data-url') {
		[...document.querySelectorAll(`[${attribute}]`)].forEach(element => {
			element.addEventListener('click', () => {
				window.location.href = element.getAttribute(attribute);
			});
			/// Sur les LR Belge (#13768), on supprime l'attribut
			if (settingsApp.section === 'listeresultats') {
				delete element.dataset.voyage;
			}
		});
	},

	loader: {
		classLibelle: 'loading',
		state: false,
		loaded: function () {
			document.body.classList.remove(this.classLibelle);
			this.state = false;
		},
		loading: function () {
			document.body.classList.add(this.classLibelle);
			this.state = true;
		}
	},

	flecheScrollTop(a = '#uparrow') {
		let arrow = document.querySelector(a);
		const btnVisibility = () => {
			const visibility = ['hidden','visible']
			arrow.style.visibility = visibility[Number(window.scrollY > 50)];
			arrow.classList.toggle('fadeIn',window.scrollY > 50);
		};
		document.addEventListener('scroll', btnVisibility, { passive: true });
		arrow.addEventListener('click', () => {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		});
	},

	configCss(settings) {
		document.documentElement.classList.remove('nojs');
		const classDevice = ['not-touch','touch'],
					pageNotIpad = ['produit','inscription','relanceoption','relancedevis','relancesolde','paiementlibre'];
		let classBody = [
			classDevice[Number(settings.helpers.isTouchDevice())]
		]
		if (this.NAVIGATOR.isSafari() || this.NAVIGATOR.isMobileSafari()) {
			classBody.push('safari');
		}
		if ((this.NAVIGATOR.isiPad() || this.NAVIGATOR.isDeviceiPad(settings)) && pageNotIpad.includes(settingsApp.section)) {
			classBody.push('isiPad');
		}
		document.body.classList.add(...classBody);
	},

	resizeScreen(config, self) {
		let orientationScreen = config.helpers.getOrientation();
		window.addEventListener('resize', () => {
			self.configCss(config);
			if (self.NAVIGATOR.isMobileSafari()) {
				if (orientationScreen !== config.helpers.getOrientation()) {
					window.location.reload()
				}
			}
		});
	},

	// * MODULES
	ErreurJs(title, message, objectConfig) {

		let url = "/Application/ErreurJs",
			data = {
				title: title,
				message: message,
				objectConfig: objectConfig
			};

		fetch(url, {
				method: 'POST',
				headers: {
					'X-Robots-Tag': 'noindex, nofollow'
				},
				body: new URLSearchParams(data)
			})
			.catch(function (err) {
				// There was an error
				console.warn('Something went wrong.', err);
			});
	}
}
module.exports = commons;