/**
 * Le block <box-rgpd> appelé pour informer de la politiqué RGPD de Terdav
 */
class rgpd extends HTMLElement {
	constructor() {
		super();

		// Create a shadow root
		const shadow = this.attachShadow({
			mode: 'open'
		});

		// Create element
		const wrapper = document.createElement('div');
		const text = document.createElement('div');

		// Le libellé du block
		const span = document.createElement('span');
		span.textContent = 'En savoir plus sur notre politique de confidentialité des données';

		// Le contenu du bloc à afficher
		text.id = "rgpdMsg";
		text.innerHTML = this.getAttribute('msg');

		// Styles
		const style = document.createElement('style');
		style.textContent = `
			div {
				margin-top: 20px;
				font-size: 12px;
			}
			span {
				cursor:pointer;
				text-decoration:underline;
			}
			#rgpdMsg {
				max-height:0;
				line-height: 1.2;
				text-align: justify;
				overflow: hidden;
				transition: max-height 1s ease;
			}

			a {
				color: var(--app-color-primary);
			}

			#rgpdMsg.is-show {
				max-height: none;
				transition: max-height 0.25s ease-in;
			}
		`;

		// On ajoute les éléments
		wrapper.appendChild(span);
		wrapper.appendChild(text);
		shadow.appendChild(style);
		shadow.appendChild(wrapper);
	}
	connectedCallback() {
		this.addEventListener("click", this.onclick);
	}
	onclick() {
		this.shadowRoot.querySelector('#rgpdMsg').classList.toggle('is-show');
	}
}

/**
 * Le block <box-niveaux data-niveaux="<%= infosCircuit.codeLevel %>"></box-niveaux> qui affiche 5 bullets avec le niveau correspondant
 */
class niveaux extends HTMLElement {
	constructor() {
		super();

		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		const width = this.dataset.width === undefined ? 90 : this.dataset.width;
		const height = this.dataset.height === undefined ? 14 : this.dataset.height;
		let fillColor = this.dataset.fillColor === undefined ? getComputedStyle(document.documentElement).getPropertyValue('--app-color-primary') : this.dataset.fillColor;

		svg.setAttribute('width', width);
		svg.setAttribute('height', height);

		if (this.dataset.niveaux !== undefined) {
			let posX = 5;
			const level = parseInt(this.dataset.niveaux);
			const activite = this.dataset.activite === undefined ? 0 : parseInt(this.dataset.activite);

			if (activite === 91 || activite === 94) {
				for (let i = 1; i <= 5; i++) {
					const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
					path.setAttribute('d', 'M6.2,0.1C5.4,4.4,7.7,5.5,11.1,6c1.1,0.2,2.4-0.1,3.1,0.6c0,1.3,0.4,1.9,0.1,2.7c-0.6,1.5-5.2,2.2-7.5,1.4 c-0.6-0.2-0.9-1-1.4-1c-0.8,0-0.9,1-1.4,1.2c-5.9,1-3.2-5.1-3.9-8.8c0.4-1,2.2-1.4,3.1-0.5C4.5,1.5,4.3-0.3,6.2,0.1');
					let pos = 18 * (i - 1);
					path.setAttribute('transform', `translate(${pos}, 0)`);
					if (i <= level) {
						path.setAttribute('fill', fillColor);
					}
					svg.appendChild(path);
				}

			} else {
				for (let i = 1; i <= 5; i++) {
					const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
					if (i > level) {
						fillColor = "#bbb";
					}
					circle.setAttribute('fill', fillColor);
					circle.setAttribute('cx', posX);
					circle.setAttribute('cy', 8);
					circle.setAttribute('r', 5);
					svg.appendChild(circle);
					posX += 15;
				}
			}
		}

		if (this.dataset.engagement !== undefined) {
			let posX = 5;
			const level = parseInt(this.dataset.engagement);
			for (let i = 0; i < 5; i++) {
				const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
				if (i + 1 > level) {
					fillColor = "#bbb";
				}
				rect.setAttribute('x', 20 * i);
				rect.setAttribute('y', 30 - posX);
				rect.setAttribute('rx', 2);
				rect.setAttribute('ry', 2);
				rect.setAttribute('width', 15);
				rect.setAttribute('height', posX);
				rect.setAttribute('fill', fillColor);
				svg.appendChild(rect);
				posX += 5;
			}

		}

		this.appendChild(svg);
	}
}

/**
 * Gestion de l'affichage du block téléphone optionnel
 */
class toggleField extends HTMLElement {
	constructor() {
		super();

		// Create a shadow root
		const shadow = this.attachShadow({
			mode: 'open'
		});

		// Create element
		const wrapper = document.createElement('div');

		// Le libellé du block
		const span = document.createElement('span');
		span.textContent = 'Ajouter un numéro de téléphone fixe';

		// Styles
		const style = document.createElement('style');
		style.textContent = ``;

		// On ajoute les éléments
		wrapper.appendChild(span);
		shadow.appendChild(wrapper);
	}
	connectedCallback() {
		this.addEventListener("click", this.onclick);
	}
	onclick() {
		//this.shadowRoot.querySelector('#rgpdMsg').classList.toggle('is-show');
	}
}