'use strict';

/// Dans /src/scripts/common/

module.exports = class toolsPromise {
	#optionsPromise = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		},
		body: ''
	}

	get optionsPromise() {
		return this.#optionsPromise;
	}
	set optionsPromise(x) {
		this.#optionsPromise.body = x;
	}

	getPromiseJson(url, params) {
		let options = params ?? this.optionsPromise;
		return new Promise(async function (resolve, reject) {
			const res = await fetch(url, options)
			resolve(res.json());
			reject(res);
		});
	}
	getPromiseTxt(url) {
		let options = this.optionsPromise;
		return new Promise(async function (resolve, reject) {
			const res = await fetch(url, options)
			resolve(res.text());
			reject(res);
		});
	}
}