////
////	Gestion des produits sélectionnés
////	
'use strict';

let helpers = require('common/helpers');
const NB_MAX_CIRCUITS = settingsApp.favoris.nbMaxCircuits || 4; // Nombre de circuits dans mes favoris = 4 par défaut sinon valeur definie via settingsApp.favoris (head-terdav.ascx)
const NOM_COOKIE = settingsApp.favoris.nomCookie || 'myselection'; // Nom du cookie Mes favoris = myselection par défaut sinon valeur definie via settingsApp.favoris (head-terdav.ascx)
const DUREE_COOKIE = settingsApp.favoris.dureeCookie || 365; // Durée du cookie Mes favoris = myselection par défaut sinon valeur definie via settingsApp.favoris (head-terdav.ascx)
const ARRAY_ID_LINK_SELECTION = settingsApp.favoris.linkSelectionIds || ['yourSelectionLink', 'yourSelectionNoLink'];
const TEXTE_MAX_CIRCUIT_FAVORIS = settingsApp.favoris.textMax || 'La sélection ne peut comporter que 4 voyages au maximum';
const AREAS_WITH_TOGGLE_FAVORIS = settingsApp.favoris.areaWithToggleFavoris || ['listeresultats', 'produit', 'selection'];

const selection = {
	init: function (settings) {
		/// On vérifie la présence du cookie mySelection
		/// On affiche ou l'on masque le lien /selection dans .nav--account
		this.setLinkOrNot();
		let pageWithToggleFavoris = false;
		for (var i = 0; i < AREAS_WITH_TOGGLE_FAVORIS.length; i++) {
			if (helpers.getPage(AREAS_WITH_TOGGLE_FAVORIS[i])) {
				pageWithToggleFavoris = true;
			}
		}

		if (pageWithToggleFavoris) {
			/// On appelle les deux méthodes suivantes que si l'on peut ajouter ou retirer un circuit de la sélection
			this.checkFavori('[data-codeproduit]');
			this.addCircuit('[data-codeproduit]');
		}
	},
	/// Méthodes principales
	setLinkOrNot() {
		this._hasFavori() ? this._toggleLink(ARRAY_ID_LINK_SELECTION[0], ARRAY_ID_LINK_SELECTION[1]) : this._toggleLink(ARRAY_ID_LINK_SELECTION[1], ARRAY_ID_LINK_SELECTION[0]);
	},
	_toggleLink(idShow, idHide) {
		helpers.show(document.getElementById(idShow))
		helpers.hide(document.getElementById(idHide))
	},
	deleteCircuit(codeProduit) {
		/// On stocke les codes produit du cookie dans un tableau 
		/// on supprime le cookie puis on pousse les codes produit restants
		let mesFavoris = this._convertFavorisToArray(),
			mesFavorisNew = [];
		this._deleteFavori(NOM_COOKIE);

		for (var i = 0; i < mesFavoris.length; i++) {
			if (mesFavoris[i] === codeProduit) {
				this._setElementNotActive(codeProduit)
			} else {
				mesFavorisNew.push(mesFavoris[i])
			}
		}
		if (mesFavorisNew.length > 0) {
			this._setFavori(mesFavorisNew.join('_'));	
			if (helpers.getPage('selection')) {
				document.getElementById(`selected-${codeProduit}`).remove();
			}
		} else {
			this._deleteFavori(NOM_COOKIE);
			if (helpers.getPage('selection')) {
				window.location.href = '/';
			}
		}
		this.setLinkOrNot();
	},
	addCircuit(btn) {
		let self = this;
		document.querySelectorAll(btn).forEach((element) => {
			element.addEventListener('click', function (e) {
				let mesFavoris = self._convertFavorisToArray(),
					codeProduitData = e.target.dataset.codeproduit.toLowerCase();

				/// On empêche l'ajout d'un circuit si NB_MAX_CIRCUITS est atteint
				if (self.hasMaxSelectedProducted(mesFavoris, NB_MAX_CIRCUITS)) {
					if (self._isCodeProduitInSelection(e.target.dataset.codeproduit)) {
						self.deleteCircuit(codeProduitData);
					} else {
						self.popupAlert()
					}
				} else {
					/// Si le circuit a déjà été ajouté
					if (mesFavoris.includes(codeProduitData)) {
						self.deleteCircuit(codeProduitData);
						self._setElementNotActive(codeProduitData);
					}
					/// On ajoute le circuit aux favoris
					/// On met à jour le bouton
					else {
						self._setFavori(codeProduitData);
						self._setElementActive(codeProduitData);
						self._hasFavori() && self._toggleLink(ARRAY_ID_LINK_SELECTION[0], ARRAY_ID_LINK_SELECTION[1]);
					}
				}
			})
		})
	},
	checkFavori(btn) {
		let self = this;
		document.querySelectorAll(btn).forEach((element) => {
			let codeProduit = element.dataset.codeproduit,
				mesFavoris = self._convertFavorisToArray();

			if (mesFavoris.length > 0 && mesFavoris.includes(codeProduit)) {
				self._setElementActive(codeProduit);
			}
		});
	},

	/// Méthodes utilitaires
	_getFavoris() {
		return helpers.getCookie(NOM_COOKIE);
	},
	_hasFavori() {
		return helpers.getCookie(NOM_COOKIE) === undefined ? false : true;
	},
	_setFavori(codeProduit) {
		let oldFavoris = this._getFavoris() === undefined ? '' : `${this._getFavoris()}_`,
			newFavoris = `${oldFavoris}${codeProduit}`;
		helpers.setCookie(NOM_COOKIE, newFavoris, DUREE_COOKIE)
	},
	_deleteFavori(name) {
		helpers.deleteCookie(name);
	},
	popupAlert() {
		alert(TEXTE_MAX_CIRCUIT_FAVORIS);
	},
	hasMaxSelectedProducted(array, int) {
		return array.length === int;
	},
	_convertFavorisToArray() {
		return this._hasFavori() ? this._getFavoris().split('_') : '';
	},
	_isCodeProduitInSelection(codeProduit) {
		let arr = this._convertFavorisToArray();
		return arr.includes(codeProduit);
	},
	_setElementActive(codeProduit) {
		let element = document.querySelector(`[data-codeproduit=${codeProduit}]`),
			page = helpers.getPage();
		switch (page) {
		case 'listeresultats':
			element.dataset.state = 'added';
			element.title = 'Retirer de mes favoris';
			break;
		default:
			console.log(`Le produit ${codeProduit} a été ajouté à mes favoris`);
		}
	},
	_setElementNotActive(codeProduit) {
		let element = document.querySelector(`[data-codeproduit=${codeProduit}]`),
			page = helpers.getPage();

		switch (page) {
		case 'listeresultats':
			delete element.dataset.state;
			element.title = 'Ajouter à mes favoris';
			break;
		default:
			console.log(`Le produit ${codeProduit} a été retiré de mes favoris`);
		}
	},
	getConfig() {
		function log(nb_max_circuits, nom_cookie, duree_cookie, array_id_link_selection, texte_max_circuit_favoris, areas_with_toggle_favoris) {
			this.nb_max_circuits = nb_max_circuits;
			this.nom_cookie = nom_cookie;
			this.duree_cookie = duree_cookie;
			this.array_id_link_selection = array_id_link_selection.toString();
			this.texte_max_circuit_favoris = texte_max_circuit_favoris;
			this.areas_with_toggle_favoris = areas_with_toggle_favoris.join(', ');
		}
		console.table(new log(NB_MAX_CIRCUITS, NOM_COOKIE, DUREE_COOKIE, ARRAY_ID_LINK_SELECTION, TEXTE_MAX_CIRCUIT_FAVORIS, AREAS_WITH_TOGGLE_FAVORIS));
	}
};

module.exports = selection;