let config = config || {};

module.exports = class appMenuNavigation {

	#itemsMenu = document.querySelectorAll('.app__menu [data-target]');
	#objBackLibelles = {
		ongletDestinations: 'Nos destinations',
		ongletEnvies: 'Nos envies',
		ongletEsprit: 'Notre esprit'
	}

	constructor(config) {
		this.config = config;
	}

	initialize() {
		this.event();

		/// Module connexion espace client
		const appEC = require("common/espaceClient");
		const espaceClient = new appEC();
		espaceClient.initialize();

		/// Spécificités mobile
		if (this.isMenuMobile) {
			/// Onglet envies
			document.querySelectorAll('.container--envies details').forEach(el => {
				el.removeAttribute('open')
			})
			/// Tab d'un onglet : sur mobile, aucun n'est actif
			document.querySelectorAll('.tab-content').forEach(el => el.classList.remove('is-active'))
		}
	}

	get defaultZone() {
		if (document.getElementById('zoneParDefaut') !== null) {
			return document.getElementById('zoneParDefaut').value;
		}
	}
	get isMenuMobile() {
		return helpers.getWindowWidth() < settingsApp.breakpoint;
	}
	get hasTabOpen() {
		return document.body.dataset.tab !== undefined
	}

	set libelleBackBtn(libelle) {
		document.querySelector('[data-button="back-menu"]').innerText = libelle
	}

	event() {

		let self = this;

		document.body.addEventListener('click', (e) => {
			const {
				target
			} = e;

			/// Click sur les liens de 1er niveau : onglet ou fermeture ou go to url
			if (target.dataset.parent === 'menu') {
				let isOpen = JSON.parse(target.getAttribute('aria-expanded'));
				let ongletTarget = target.dataset.target;
				if (isOpen) {
					//Correspond à un [href] vide
					if (target.pathname === '/' || target.href === undefined) {
						e.preventDefault();
						self._closeOnglet(document.getElementById(target.dataset.target))
					} else {
						// On click sur un lien vers la page courante
						if (target.pathname === window.location.pathname) {
							e.preventDefault();
							self._closeOnglet(document.getElementById(target.dataset.target))
						}
					}
				} else {
					e.preventDefault();
					ongletTarget !== undefined && self._openOnglet(target, ongletTarget);
				}
			}
			/// Click button fermeture onglet
			else if (target.dataset.action === 'closeOnglet') {
				self._closeOnglet(target.parentElement)
			}
			/// Click sur l'ombré [desktop uniquement]
			else if (target.localName === 'body' && [...target.classList].includes('has-onglet-open')) {
				self._closeOnglet(document.getElementById(target.dataset.onglet))
			}
			/// Bouton open menu on mobile
			else if (target.dataset.button === 'toggle-menu') {
				document.querySelector('.app__menu').classList.toggle('is-open');
				settingsApp.app !== 'TCA' && document.getElementById('espace-client').classList.remove('is-active');
				delete document.body.dataset.popin;
				target.classList.toggle('tcon-transform');

				if (document.body.dataset.open !== undefined) {
					if (JSON.parse(document.body.dataset.open)) {
						self._hideAll();
					}
					document.body.dataset.open = !JSON.parse(document.body.dataset.open);
				} else {
					document.body.dataset.open = true;
				}

				// Création d'un événement state menu
				document.body.dispatchEvent(
					new CustomEvent('stateMenu', {
						bubbles: true,
						detail: {
							state: () => {
								return document.body.dataset.open
							}
						}
					})
				);
			}
			/// Click sur tab d'un onglet
			else if ((target.parentElement != null && target.parentElement.dataset.targetOnglet != null) || target.dataset.targetOnglet != null) {
				e.preventDefault();
				if (target.parentElement.dataset.targetOnglet !== '') {
					self.tabSystem(target.parentElement.dataset.targetOnglet ?? target.dataset.targetOnglet);
				}
			}
			else if (target.dataset.button === 'back-menu') {
				self._backNavigation(target)
			}
			/// Bouton open search on mobile
			else if (target.dataset.button == 'toggle-recherche') {
				target.classList.toggle('tcon-transform');
				document.querySelector('.search--banner').classList.toggle('is-active');

				if (document.body.dataset.search !== undefined) {
					// if (JSON.parse(document.body.dataset.search)) {
					// 	self._hideAll();
					// }
					document.body.dataset.search = !JSON.parse(document.body.dataset.search);
				} else {
					document.body.dataset.search = true;
				}

				if (document.querySelector('.app__account').getAttribute('style') === null) {
					helpers.show(document.querySelector('.app__account'))
					document.querySelector('.app__account').style.position = 'absolute';
				} else {
					document.querySelector('.app__account').removeAttribute('style');
				}
			}
			// Recherche avancée différente TDV|TCA vs GN : \www.terdav.com\Views\Home\Index.aspx > appel du module
			else if (target.dataset.recherche === 'advanced' && settingsApp.app === 'GNG') {
				const app = require("common/moteurRecherche");
				const moteurRecherche = new app(settingsApp);
				moteurRecherche.loadMoteurRecherche();
			} else if (target.id === "btnSearchEngineClose") {
				document.body.classList.remove('has-searchengine-open');
				document.getElementById('searchengine').classList.remove('is-shown')
			}
			// else {
			// 	console.log(target)
			// }
		})
	}

	_hideAll() {
		delete document.body.dataset.onglet;
		delete document.body.dataset.tab;
		document.body.classList.remove('has-onglet-open');
		document.querySelectorAll('.app__onglet').forEach(el => {
			el.classList.remove('is-open')
		});
		document.querySelectorAll('.tab-content').forEach(el => {
			el.classList.remove('is-active')
		});
		document.querySelectorAll('.item--section').forEach(el => {
			el.setAttribute('aria-expanded', false)
		});
	}
	_openOnglet(link, onglet) {
		this.#itemsMenu.forEach(el => {
			this._closeOnglet(document.getElementById(el.dataset.target))
		})
		link.setAttribute('aria-expanded', true);
		document.getElementById(onglet).classList.add('is-open');
		document.body.classList.add('has-onglet-open');
		document.body.dataset.onglet = onglet;

		if (onglet === "ongletDestinations" && document.getElementById('divContenuZone').innerHTML.trim() === '' && helpers.getWindowWidth() >= settingsApp.breakpoint) {
			settingsApp.app === 'GNG' && this._GetMenu(this.defaultZone, '/Menu/GetMenuParZone', 'divContenuZone');
		} else if (onglet === "ongletEsprit") {
			if (document.getElementById('esprit').innerHTML.trim() === '') {
				this._GetMenu(null, '/Menu/MenuEsprit', 'esprit');
			} else {
				document.querySelector('#ongletEsprit .tab-content').classList.add('is-active')
			}
		}

		if (helpers.getWindowWidth() < settingsApp.breakpoint) {
			this.libelleBackBtn = this.#objBackLibelles[onglet];
		}
	}
	_closeOnglet(onglet) {
		if (onglet !== null) {
			document.querySelector(`[data-target="${onglet.id}"]`).setAttribute('aria-expanded', false);
			onglet.classList.remove('is-open');
			document.body.classList.remove('has-onglet-open');
			delete document.body.dataset.onglet;
		}
	}
	_backNavigation(el) {
		let ongletOpen = document.body.dataset.onglet;


		if (this.hasTabOpen) {
			document.querySelectorAll('.tab-content').forEach(el => el.classList.remove('is-active'));
			this.libelleBackBtn = this.#objBackLibelles[ongletOpen];
			delete document.body.dataset.tab;
		} else {
			document.getElementById(ongletOpen).classList.remove('is-open');
			document.body.classList.remove('has-onglet-open');
			document.querySelector(`[data-target="${ongletOpen}"]`).setAttribute('aria-expanded', false);
			delete document.body.dataset.onglet;
		}
	}
	_GetMenu(param, urlPost, container) {
		let data = null;
		if (urlPost === '/Menu/GetMenuParZone') {
			data = {
				slugzone: param
			}
		} else if (param != null) {
			data = {
				urlDestination: param
			}
		}

		fetch(urlPost, {
			method: 'POST',
			headers: {
				'X-Robots-Tag': 'noindex, nofollow'
			},
			body: new URLSearchParams(data)
		}).then(function (response) {
			// The API call was successful!
			return response.text();
		}).then(function (html) {
			// This is the HTML from our response as a text string
			document.getElementById(container).innerHTML += html;
		}).catch(function (err) {
			// There was an error
			console.warn('Something went wrong.', err);
		});
	}

	///
	/// Fonction gérant l'affichage du contenu des sous-onglets
	///
	tabSystem(id) {
		if (this.isMenuMobile) {
			document.body.dataset.tab = id;
			document.querySelectorAll('.tab-content').forEach(el => el.classList.remove('is-active'))

			if (helpers.elementExist(`#${id}`)) {
				document.getElementById(id).classList.add('is-active');
			}

			if (document.body.dataset.onglet === "ongletDestinations") {
				let zone = id.substring(5);
				this.libelleBackBtn = document.querySelector(`[data-target-onglet="${id}"]`).dataset.label;
				if (document.getElementById(id) === null || document.getElementById(id).innerHTML.trim() === '') {
					this._GetMenu(zone, '/Menu/GetMenuParZone', 'divContenuZone')
				};
			}
		} else {
			if (document.querySelector(`[data-target-onglet="${id}"]`).className.includes('is-active')) {
				if (document.querySelector(`[data-target-onglet="${id}"]`).href !== '') {
					window.location.href = document.querySelector(`[data-target-onglet="${id}"] a`).href
				}
			}
			[...document.querySelector(`[data-target-onglet="${id}"]`).parentElement.children].forEach(el => el.classList.remove('is-active'));
			document.querySelector(`[data-target-onglet="${id}"]`).classList.add('is-active');


			if (document.getElementById(id) === null) {
				this._GetMenu(id.replace('zone-', ''), '/Menu/GetMenuParZone', 'divContenuZone');
				[...document.getElementById('divContenuZone').children].forEach(el => el.classList.remove('is-active'));
				settingsApp.app != 'GNG' && document.getElementById('zone-selection').classList.remove('is-active');
			} else {
				[...document.getElementById('divContenuZone').children].forEach(el => el.classList.remove('is-active'));
				settingsApp.app != 'GNG' && document.getElementById('zone-selection').classList.remove('is-active');
				document.getElementById(id).classList.add('is-active');
			}
		}
	}
}