////
////	Barre de recherche pages internes
////	(hors tunnel, devis et home)
////	Function appelée dans menuNavigation.js

const commons = require("./commons");
const helpers = require("./helpers");

module.exports = class searchBar {
	init() {
		var input = document.getElementById('autocomplete');
		const inputSearch = document.getElementById('hidden-input-search');
		const URL_AUTOCOMPLETE = '/MoteurRecherche/GetRechercheLibre';
		// if (settingsApp.section === 'home') {
		// 	document.querySelector('#moteurRecherche .btn-search').disabled = (inputSearch.value === '')
		// }

		if (helpers.getWindowWidth() >= settingsApp.breakpoint && settingsApp.section !== 'home') {
			document.querySelector('#moteurRecherche .btn-search').addEventListener('click', function (ev) {
				document.querySelector('.search-box').classList.remove('is-open');
				!document.querySelector('.search--banner').classList.contains('is-active') && input.focus();
				document.querySelector('.search--banner').classList.toggle('is-active');
			});

			document.body.addEventListener('click', function (e) {
				if (!e.target.classList.contains('btn-search') && !e.target.classList.contains('input-search')) {
					document.querySelector('.search--banner').classList.remove('is-active');
					inputSearch.value = '';
					input.value = '';
				}
			})
		} else {
			//console.log('home ')
		}

		autocomplete({
			minLength: 3,
			input: input,
			fetch: function (text, update) {
				text = text.toLowerCase();
				var request = [];
				var array;
				if (text.length > 2) {
					var xhr = new XMLHttpRequest();
					xhr.open('GET', URL_AUTOCOMPLETE + '?term=' + text);
					xhr.send(null);
					xhr.onreadystatechange = function () {
						var DONE = 4; // readyState 4 means the request is done.
						var OK = 200; // status 200 is a successful return.
						if (xhr.readyState === DONE) {
							if (xhr.status === OK) {
								array = JSON.parse(xhr.response);
							}
							// else {
							// 	console.log('Error: ' + xhr.status); // An error occurred during the request.
							// }

							var suggestions = array;

							if (Array.isArray(suggestions)) {
								try {
									update(suggestions);
									document.querySelector('.search-box').classList.add('is-open');
									/// On met à jour la value
									inputSearch.value = suggestions[1].value;
									document.querySelector('#moteurRecherche .btn-search').disabled = (inputSearch.value === '')
								} catch (error) {
									console.error(error);
								}
							} else {
								inputSearch.value = '';
								document.querySelector('#moteurRecherche .btn-search').disabled = true;
								document.querySelector('.search-box').classList.remove('is-open');
								document.querySelector('.autocomplete') !== null && document.querySelector('.autocomplete').remove();
							}
						}
					};
				} else {
					document.querySelector('.search-box').classList.remove('is-open');
					if (settingsApp.section === 'home') {
						document.querySelector('#moteurRecherche .btn-search').disabled = (inputSearch.value === '')
					}
				}
			},
			customize: function (input, inputRect, container, maxHeight) {
				container.style.maxHeight = '327px';
			},
			render: function (item, value) {
				var itemElement = document.createElement('li');

				// let hasSelected = false;
				// On ajoute une classe spécifique aux catégories
				if (item.value.indexOf('cat#') > -1) {
					itemElement.classList.add('is-categorie');
				} else {
					itemElement.title = item.label
				}
				// else if (firstValue) {
				// 	inputSearch.value = item.value;
				// 	firstValue = false;
				// 	console.log(item.label)
				// }

				itemElement.innerHTML = item.label;
				return itemElement;
			},
			onSelect: function (item) {
				const moteurRecherche = document.getElementById('moteurRecherche');

				if (item.value.indexOf('cat#') == -1 && item.value !== '') {
					inputSearch.value = item.value;
					moteurRecherche.submit();
				}
			},
		});
	}
}
